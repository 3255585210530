/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "../../../css/promenade-web.css";
import "../../../css/promenade.css";
import { useSwipeable } from "react-swipeable";
import { Modal } from "antd";
import { Button, Form, Input } from "antd";

import section1Bg from "../../../images/commercial/ongoing/promenade/section-1-bg-min.jpg";
import section1Asset2 from "../../../images/commercial/ongoing/promenade/Asset-2.png";
import section1Asset3 from "../../../images/commercial/ongoing/promenade/Asset-3.png";

import promenadeImg from "../../../images/commercial/ongoing/promenade/Promenade - Revised.png";

import section2Bg from "../../../images/commercial/ongoing/promenade/section-2-bg-min.jpg";
import section2Img2 from "../../../images/commercial/ongoing/promenade/Asset-31.png";

import section3Bg from "../../../images/commercial/ongoing/promenade/section-3-bg.png";

import section4Bg from "../../../images/commercial/ongoing/promenade/section-4-bg.png";

import extImg1 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-02.jpg";
import extImg2 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-04.jpg";
import extImg3 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-19.jpg";
import extImg4 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-22.jpg";

import intImg1 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-06-min.jpg";
import intImg2 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-07.jpg";
import intImg3 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-08.jpg";
import intImg4 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-09-min.jpg";
import intImg5 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-12.jpg";

import juneImg1 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/1.jpg";
import juneImg2 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/2.jpg";
import juneImg3 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/3.jpg";
import juneImg4 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/4.jpg";
import juneImg5 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/5.jpg";
import juneImg6 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/6.jpg";

import julyImg1 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/1.jpg";
import julyImg2 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/2.jpg";
import julyImg3 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/3.jpg";
import julyImg4 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/4.jpg";
import julyImg5 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/5.jpg";

import AugustImg1 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/1.jpg";
import AugustImg2 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/2.jpg";
import AugustImg3 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/3.jpg";
import AugustImg4 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/4.jpg";

import septImg1 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/1.jpg";
import septImg2 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/2.jpg";
import septImg3 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/3.jpg";
import septImg4 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/4.jpg";

import october2024Img1 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/1.jpg";
import october2024Img2 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/2.jpg";
import october2024Img3 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/3.jpg";
import october2024Img4 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/4.jpg";

import november2024Img1 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/1.jpg";
import november2024Img2 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/2.jpg";
import november2024Img3 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/3.jpg";
import november2024Img4 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/4.jpg";

import section7Bg from "../../../images/commercial/ongoing/promenade/section-6-img.png";
import gsap from "gsap";

import icon1 from "../../../images/commercial/ongoing/promenade/icon-2.png";
import icon2 from "../../../images/commercial/ongoing/promenade/icon-1.png";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Footer from "../../common/Footer";
import axios from "axios";

function PromenadeWeb() {
  let currentSection = useRef(0);
  let sections = useRef(null);
  let totalSections = useRef(0);
  let scrolledUp = useRef(false);
  let scrolledDown = useRef(false);
  let lastScrolledTimer = useRef(new Date().getTime());
  let currentScrolledTimer = useRef(new Date().getTime());
  let scrollTimer = useRef(null);
  let isScrolled = useRef(false);

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  const [swiped, setSwiped] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [showHeader, setShowHeader] = useState(false);
  let headerRef = useRef(null);

  const [showFooter, setShowFooter] = useState(false);

  let section2ScrollIndex = useRef(0);
  let section2ScrollTimer = useRef(null);
  let section2ScrollDiv = useRef(null);

  let section3SelectedIndex = useRef(0);
  const [section3CurrentIndex, setSection3CurrentIndex] = useState(0);

  const [section4SelectedTabIndex, setSection4SelectedTabIndex] = useState(0);
  const [section5SelectedTabIndex, setSection5SelectedTabIndex] = useState(4);

  let isSection4Animated = useRef(false);
  let section4Ref1 = useRef(null);

  let section4BgRef = useRef(null);

  const [showSection4Bg, setShowSection4Bg] = useState(false);

  const [selected4Image, setSelected4Image] = useState(null);
  const [total4Image, setTotal4Image] = useState(null);
  const [showLeft4Arrow, setShowLeft4Arrow] = useState(false);
  const [showRight4Arrow, setShowRight4Arrow] = useState(false);

  const [selected5Image, setSelected5Image] = useState(null);
  const [total5Image, setTotal5Image] = useState(null);
  const [showLeft5Arrow, setShowLeft5Arrow] = useState(false);
  const [showRight5Arrow, setShowRight5Arrow] = useState(false);
  const [clickedImage, setClickedImage] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);

  let sliderModalRef = useRef(0);

  let isSection5Animated = useRef(false);
  let section5Ref1 = useRef(null);

  let isSection6Animated = useRef(false);
  let section6Ref1 = useRef(null);

  let isSection7Animated = useRef(false);
  let section7Ref1 = useRef(null);

  let selectionBarRef = useRef(null);
  let menuItem1Ref = useRef(null);
  let menuItem2Ref = useRef(null);
  let menuItem3Ref = useRef(null);
  let menuItem4Ref = useRef(null);
  let menuItem5Ref = useRef(null);
  let menuItem6Ref = useRef(null);
  let menuItem7Ref = useRef(null);

  const [formName, setFormName] = useState("");
  const [formContact, setFormContact] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formMessage, setFormMessage] = useState("");
  const [showRequiredError, setShowRequiredError] = useState([0, 0, 0, 0]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    sections.current = document.querySelectorAll(".fade-in-section");
    totalSections.current = sections.current.length;

    document
      .querySelector(".scroll-container")
      .addEventListener("wheel", wheelFn);

    scrollToSection(currentSection.current);
  }, []);

  useEffect(() => {
    if (selected4Image || selected5Image) {
      setShowImageModal(true);
    } else {
      setShowImageModal(false);
    }
  }, [selected4Image, selected5Image]);

  useEffect(() => {
    if (showHeader) {
      if (selectionBarRef.current && menuItem1Ref.current) {
        selectionBarRef.current.style.left = `${menuItem1Ref.current.getBoundingClientRect()
          .x - 15}px`;
        selectionBarRef.current.style.width = `${menuItem1Ref.current.getBoundingClientRect()
          .width + 30}px`;
        selectionBarRef.current.style.height = `${menuItem1Ref.current.getBoundingClientRect()
          .height + 10}px`;
      }
    }
  }, [showHeader]);

  useEffect(() => {
    let newArr = [...showRequiredError];
    if (formName) {
      newArr[0] = false;
    }
    if (formContact) {
      newArr[1] = false;
    }
    if (formEmail) {
      newArr[2] = false;
    }
    if (formMessage) {
      newArr[3] = false;
    }
    setShowRequiredError(newArr);
  }, [formName, formContact, formEmail, formMessage]);

  useEffect(() => {
    if (swiped) {
      const btnContainer = document.querySelector(
        ".web-version .section-7-container .layer-1 .image-container .btn-container"
      );
      const arrowContainer = document.querySelector(
        ".web-version .section-7-container .layer-1 .image-container .btn-container .arrow-container"
      );
      if (arrowContainer && btnContainer) {
        arrowContainer.style.transform = `translateX(${btnContainer.clientWidth -
          arrowContainer.clientWidth}px)`;
      }
      setShowModal(true);
      setTimeout(() => {
        setSwiped(false);
        if (arrowContainer && btnContainer) {
          arrowContainer.style.transform = `translateX(0px)`;
        }
      }, 3000);
    }
  }, [swiped]);

  useEffect(() => {
    const carousel4Length = document.querySelectorAll(
      `.section-4-container .image-container`
    );
    const carousel5Length = document.querySelectorAll(
      `.section-5-container .image-container`
    );

    if (carousel4Length.length && carousel5Length.length) {
      setTotal4Image(carousel4Length.length);
      setTotal5Image(carousel5Length.length);
    }

    setTimeout(() => {
      setShowFooter(true);
    }, 900);
  }, [
    selected4Image,
    selected5Image,
    section4SelectedTabIndex,
    section5SelectedTabIndex,
  ]);

  useEffect(() => {
    if (selected4Image && total4Image) {
      setIsLoading(true);
      if (selected4Image === total4Image) {
        setShowLeft4Arrow(true);
        setShowRight4Arrow(false);
      } else if (selected4Image === 1) {
        setShowLeft4Arrow(false);
        setShowRight4Arrow(true);
      } else {
        setShowRight4Arrow(true);
        setShowLeft4Arrow(true);
      }
      if (clickedImage) {
        slideToImage(4);
      }
    }

    if (selected5Image && total5Image) {
      setIsLoading(true);
      if (selected5Image === total5Image) {
        setShowRight5Arrow(false);
        setShowLeft5Arrow(true);
      } else if (selected5Image === 1) {
        setShowLeft5Arrow(false);
        setShowRight5Arrow(true);
      } else {
        setShowRight5Arrow(true);
        setShowLeft5Arrow(true);
      }
      if (clickedImage) {
        slideToImage(5);
      }
    }
  }, [
    selected4Image,
    selected5Image,
    total4Image,
    total5Image,
    section4SelectedTabIndex,
    section5SelectedTabIndex,
  ]);

  useEffect(() => {
    if (section4BgRef.current) {
      section4BgRef.current.style.top = `${document.querySelector("#section-3")
        ?.offsetTop + 50}px`;
    }
  }, [section3SelectedIndex.current, currentSection.current]);

  function wheelFn(event) {
    event.preventDefault();

    lastScrolledTimer.current = currentScrolledTimer.current;

    if (!isScrolled.current) {
      isScrolled.current = true;

      if (event.deltaY > 0) {
        if (!scrolledUp.current) {
          scrolledUp.current = true;
          scrolledDown.current = false;

          if (
            currentSection.current === 2 &&
            section3SelectedIndex.current !== 2
          ) {
            section3SelectedIndex.current++;
            setSection3CurrentIndex(section3SelectedIndex.current);
          } else {
            if (currentSection.current < totalSections.current - 1) {
              currentSection.current++;
            }
          }
        }
      } else {
        if (!scrolledDown.current) {
          scrolledDown.current = true;
          scrolledUp.current = false;

          if (
            currentSection.current === 2 &&
            section3SelectedIndex.current !== 0
          ) {
            section3SelectedIndex.current--;
            setSection3CurrentIndex(section3SelectedIndex.current);
          } else {
            if (currentSection.current > 0) {
              currentSection.current--;
            }
          }
        }
      }

      scrollFn();
    }
  }

  // scrollTimer.current = setInterval(() => {
  //   currentScrolledTimer.current = new Date().getTime();
  //   if(currentScrolledTimer.current > lastScrolledTimer.current + 1000) {
  //     scrolledUp.current = false
  //     scrolledDown.current = false
  //     isScrolled.current = false
  //   }
  // }, 1000)

  function scrollSynopsis() {
    clearTimeout(section2ScrollTimer.current);
    section2ScrollTimer.current = setTimeout(() => {
      if (section2ScrollDiv.current) {
        section2ScrollIndex.current = section2ScrollIndex.current + 1;
        if (section2ScrollIndex.current === 3) section2ScrollIndex.current = 0;

        section2ScrollDiv.current.style.transform = `translateX(${section2ScrollIndex.current *
          100 *
          -1}%)`;
      }

      scrollSynopsis();
    }, 3000);
  }

  function scrollFn() {
    // if(!isScrolled.current) {
    isScrolled.current = true;
    if (currentSection.current !== 0) {
      setShowHeader(true);
      setShowFooter(false);
    } else {
      setShowHeader(false);
      setShowFooter(true);
    }

    if (currentSection.current === 1) {
      scrollSynopsis();
    } else {
      clearTimeout(section2ScrollTimer.current);
    }

    setTimeout(() => {
      if (currentSection.current === 1) {
        if (headerRef.current) {
          headerRef.current.classList.remove("dark-mode");
        }
      } else {
        if (headerRef.current) {
          headerRef.current.classList.add("dark-mode");
        }
      }
    }, 100);

    if (currentSection.current === 3) {
      if (!isSection4Animated.current) {
        isSection4Animated.current = true;
        if (section4Ref1.current) {
          gsap.to(".section-4-container .layer-1 .tab-menu", {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: "power1.inOut",
            delay: 0.2,
          });
          gsap.to(
            ".section-4-container .layer-1 .main-content.main-content-tab-1 .row-1",
            {
              opacity: 1,
              y: 0,
              duration: 0.8,
              ease: "power1.inOut",
              delay: 0.7,
            }
          );
          gsap.to(
            ".section-4-container .layer-1 .main-content.main-content-tab-1 .row-2",
            {
              opacity: 1,
              y: 0,
              duration: 0.8,
              ease: "power1.inOut",
              delay: 1.3,
            }
          );
        }
      }
    }

    if (currentSection.current === 4) {
      if (!isSection5Animated.current) {
        isSection5Animated.current = true;
        if (section5Ref1.current) {
          gsap.to(".section-5-container .layer-1 .tab-menu", {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: "power1.inOut",
            delay: 0.2,
          });
          gsap.to(".section-5-container .layer-1 .main-content .row-1", {
            opacity: 1,
            y: 0,
            duration: 0.8,
            ease: "power1.inOut",
            delay: 0.7,
          });
          gsap.to(".section-5-container .layer-1 .main-content .row-2", {
            opacity: 1,
            y: 0,
            duration: 0.8,
            ease: "power1.inOut",
            delay: 1.3,
          });
        }
      }
    }

    if (currentSection.current === 5) {
      if (!isSection6Animated.current) {
        isSection6Animated.current = true;
        if (section6Ref1.current) {
          gsap.to(".section-6-container .layer-1 .image-container", {
            opacity: 1,
            y: 0,
            duration: 1.2,
            ease: "power1.inOut",
          });
        }
      }
    }

    if (currentSection.current === 6) {
      if (!isSection7Animated.current) {
        isSection7Animated.current = true;
        if (section7Ref1.current) {
          gsap.to(".section-7-container .layer-1 .image-container", {
            opacity: 1,
            x: 0,
            duration: 0.8,
            ease: "power1.inOut",
          });
        }
      }
    }

    scrollToSection(currentSection.current);

    if (currentSection.current === 3 || currentSection.current === 4) {
      setShowSection4Bg(true);
      if (currentSection.current === 4) {
        section4BgRef.current.style.transform = `translateY(${-(
          section4BgRef.current.offsetHeight +
          section4BgRef.current.offsetTop -
          (document.querySelector("#section-4")?.offsetTop +
            document.querySelector("#section-4")?.offsetHeight)
        )}px)`;
      } else {
        section4BgRef.current.style.transform = `translateY(${0}px)`;
      }
    } else {
      setShowSection4Bg(false);
    }
    // }
  }

  function scrollToSection(index) {
    if (index === 1 && selectionBarRef.current && menuItem1Ref.current) {
      selectionBarRef.current.style.opacity = `1`;
      selectionBarRef.current.style.left = `${menuItem1Ref.current.getBoundingClientRect()
        .x - 15}px`;
      selectionBarRef.current.style.width = `${menuItem1Ref.current.getBoundingClientRect()
        .width + 30}px`;
    } else if (index === 2 && selectionBarRef.current && menuItem2Ref.current) {
      if (section3SelectedIndex.current === 0) {
        selectionBarRef.current.style.opacity = `1`;
        selectionBarRef.current.style.left = `${menuItem2Ref.current.getBoundingClientRect()
          .x - 15}px`;
        selectionBarRef.current.style.width = `${menuItem2Ref.current.getBoundingClientRect()
          .width + 30}px`;
      } else if (section3SelectedIndex.current === 1) {
        selectionBarRef.current.style.opacity = `1`;
        selectionBarRef.current.style.left = `${menuItem3Ref.current.getBoundingClientRect()
          .x - 15}px`;
        selectionBarRef.current.style.width = `${menuItem3Ref.current.getBoundingClientRect()
          .width + 30}px`;
      } else if (section3SelectedIndex.current === 2) {
        selectionBarRef.current.style.opacity = `1`;
        selectionBarRef.current.style.left = `${menuItem4Ref.current.getBoundingClientRect()
          .x - 15}px`;
        selectionBarRef.current.style.width = `${menuItem4Ref.current.getBoundingClientRect()
          .width + 30}px`;
      }
    } else if (index === 3 && selectionBarRef.current && menuItem5Ref.current) {
      selectionBarRef.current.style.opacity = `1`;
      selectionBarRef.current.style.left = `${menuItem5Ref.current.getBoundingClientRect()
        .x - 15}px`;
      selectionBarRef.current.style.width = `${menuItem5Ref.current.getBoundingClientRect()
        .width + 30}px`;
    } else if (index === 4 && selectionBarRef.current && menuItem6Ref.current) {
      selectionBarRef.current.style.opacity = `1`;
      selectionBarRef.current.style.left = `${menuItem6Ref.current.getBoundingClientRect()
        .x - 15}px`;
      selectionBarRef.current.style.width = `${menuItem6Ref.current.getBoundingClientRect()
        .width + 30}px`;
    } else if (index === 5 && selectionBarRef.current && menuItem7Ref.current) {
      selectionBarRef.current.style.opacity = `1`;
      selectionBarRef.current.style.left = `${menuItem7Ref.current.getBoundingClientRect()
        .x - 15}px`;
      selectionBarRef.current.style.width = `${menuItem7Ref.current.getBoundingClientRect()
        .width + 30}px`;
    } else {
      if (selectionBarRef.current) {
        selectionBarRef.current.style.opacity = `0`;
      }
    }

    sections.current.forEach((section, i) => {
      if (i === index)
        document.querySelector(`#section-${i}`).classList.add("show");
      else document.querySelector(`#section-${i}`).classList.remove("show");

      document
        .querySelector(`#section-${index}`)
        .scrollIntoView({ behavior: "smooth" });
    });

    scrollTimer.current = setTimeout(() => {
      currentScrolledTimer.current = new Date().getTime();
      if (true) {
        scrolledUp.current = false;
        scrolledDown.current = false;
        isScrolled.current = false;
      }
    }, 1000);
  }

  const handlers = useSwipeable({
    onSwipeStart: () => setSwiped(true),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  function slideLeft(index) {
    setClickedImage(false);
    const carouselItems = document.querySelectorAll(
      `#carousel-container-${index} .carousel_item`
    );
    if (carouselItems.length) {
      sliderModalRef.current = sliderModalRef.current + 100;
      carouselItems.forEach((item, index) => {
        item.style.transform = `translateX(${sliderModalRef.current}%)`;
      });
      if (index === 4) setSelected4Image((prevIndex) => prevIndex - 1);
      else if (index === 5) setSelected5Image((prevIndex) => prevIndex - 1);
    }
  }

  function slideRight(index) {
    setClickedImage(false);
    const carouselItems = document.querySelectorAll(
      `#carousel-container-${index} .carousel_item`
    );
    if (carouselItems.length) {
      sliderModalRef.current = sliderModalRef.current - 100;
      carouselItems.forEach((item, index) => {
        item.style.transform = `translateX(${sliderModalRef.current}%)`;
      });
      if (index === 4) setSelected4Image((prevIndex) => prevIndex + 1);
      else if (index === 5) setSelected5Image((prevIndex) => prevIndex + 1);
    }
  }

  function slideToImage(index) {
    setTimeout(() => {
      const carouselItems = document.querySelectorAll(
        `#carousel-container-${index} .carousel_item`
      );

      if (carouselItems.length) {
        sliderModalRef.current =
          sliderModalRef.current -
          100 * ((selected4Image || selected5Image) - 1);
        carouselItems.forEach((item, index) => {
          item.style.transform = `translateX(${sliderModalRef.current}%)`;
          setIsLoading(false);
        });
      }
    }, 200);
  }

  function clearFormData() {
    setFormName("");
    setFormContact("");
    setFormEmail("");
    setFormMessage("");
    setShowRequiredError([0, 0, 0, 0]);
  }

  async function submitFormData() {
    let newArr = [...showRequiredError];
    if (!formName) {
      newArr[0] = true;
    } else {
      newArr[0] = false;
    }

    if (!formContact) {
      newArr[1] = true;
    } else {
      newArr[1] = false;
    }

    if (!formEmail) {
      newArr[2] = true;
    } else {
      newArr[2] = false;
    }

    if (!formMessage) {
      newArr[3] = true;
    } else {
      newArr[3] = false;
    }
    setShowRequiredError(newArr);

    if (!formName || !formContact || !formEmail || !formMessage) {
      return;
    }

    try {
      let response = await axios.post(
        "https://emailer.billiontags.com/bashyam/",
        {
          email: formEmail,
          name: formName,
          number: formContact,
          message: formMessage,
          type: "Enquiry",
        }
      );

      if (response.status === 200) {
        clearFormData();
        setShowModal(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 4000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div class="scroll-container web-version">
        <div class="content">
          <div
            ref={headerRef}
            className={`header-container ${showHeader ? "" : "hide"}`}
          >
            {/* <span className="bar">
              <span className="line line-1"></span>
              <span className="line line-2"></span>
              <span className="line line-3"></span>
            </span> */}
            <span className="selection-bar" ref={selectionBarRef}></span>
            <span
              className="menu-item"
              ref={menuItem1Ref}
              onClick={() => {
                scrollToSection(1);
                currentSection.current = 1;
                scrollFn();
              }}
            >
              Project Synopsis
            </span>
            <span
              className="menu-item"
              ref={menuItem2Ref}
              onClick={() => {
                scrollToSection(2);
                section3SelectedIndex.current = 0;
                setSection3CurrentIndex(section3SelectedIndex.current);
                currentSection.current = 2;
                scrollFn();
              }}
            >
              Project Specifications
            </span>
            <span
              className="menu-item"
              ref={menuItem3Ref}
              onClick={() => {
                scrollToSection(2);
                section3SelectedIndex.current = 1;
                setSection3CurrentIndex(section3SelectedIndex.current);
                scrollFn();
              }}
            >
              Outdoor Amenities
            </span>
            <span
              className="menu-item"
              ref={menuItem4Ref}
              onClick={() => {
                scrollToSection(2);
                section3SelectedIndex.current = 2;
                setSection3CurrentIndex(section3SelectedIndex.current);
                currentSection.current = 2;
                scrollFn();
              }}
            >
              Indoor Amenities
            </span>
            <span
              className="menu-item"
              ref={menuItem5Ref}
              onClick={() => {
                scrollToSection(3);
                currentSection.current = 3;
                scrollFn();
              }}
            >
              Gallery
            </span>
            <span
              className="menu-item"
              ref={menuItem6Ref}
              onClick={() => {
                scrollToSection(4);
                currentSection.current = 4;
                scrollFn();
              }}
            >
              Project Status
            </span>
            <span
              className="menu-item"
              ref={menuItem7Ref}
              onClick={() => {
                scrollToSection(5);
                currentSection.current = 5;
                scrollFn();
              }}
            >
              Location
            </span>
          </div>
          <section
            className="section section-1-container fade-in-section"
            id="section-0"
          >
            <div className="overlay" />
            <img alt="" src={section1Bg} className="bg-img" />
            <div className="layer-1">
              <div className="box box-1"></div>
              <div className="box box-2"></div>
              <div className="box box-3"></div>
              <div className="box box-4"></div>

              <div className="divider-container">
                <div className="divider divider-1"></div>
                <div className="divider divider-2"></div>
                <div className="divider divider-3"></div>
              </div>
            </div>
            <div className="layer-2">
              <img alt="" src={section1Asset2} className="asset-2" />
              <img alt="" src={section1Asset3} className="asset-3" />
            </div>
            <div
              className={`footer-container ${showFooter ? "" : "hide"}`}
              style={{ opacity: showFooter ? 1 : 0 }}
            >
              {/* <span className="bar">
                <span className="line line-1"></span>
                <span className="line line-2"></span>
                <span className="line line-3"></span>
              </span> */}
              <span
                className="menu-item"
                onClick={() => {
                  scrollToSection(1);
                  currentSection.current = 1;
                  scrollFn();
                }}
              >
                Project Synopsis
              </span>
              <span
                className="menu-item"
                onClick={() => {
                  scrollToSection(2);
                  section3SelectedIndex.current = 0;
                  setSection3CurrentIndex(section3SelectedIndex.current);
                  currentSection.current = 2;
                  scrollFn();
                }}
              >
                Project Specifications
              </span>
              <span
                className="menu-item"
                onClick={() => {
                  scrollToSection(2);
                  section3SelectedIndex.current = 1;
                  setSection3CurrentIndex(section3SelectedIndex.current);
                  currentSection.current = 2;
                  scrollFn();
                }}
              >
                Outdoor Amenities
              </span>
              <span
                className="menu-item"
                onClick={() => {
                  scrollToSection(2);
                  section3SelectedIndex.current = 2;
                  setSection3CurrentIndex(section3SelectedIndex.current);
                  currentSection.current = 2;
                  scrollFn();
                }}
              >
                Indoor Amenities
              </span>
              <span
                className="menu-item"
                onClick={() => {
                  scrollToSection(3);
                  currentSection.current = 3;
                  scrollFn();
                }}
              >
                Gallery
              </span>
              <span
                className="menu-item"
                onClick={() => {
                  scrollToSection(4);
                  currentSection.current = 4;
                  scrollFn();
                }}
              >
                Project Status
              </span>
              <span
                className="menu-item"
                onClick={() => {
                  scrollToSection(5);
                  currentSection.current = 5;
                  scrollFn();
                }}
              >
                Location
              </span>
            </div>
          </section>

          <section
            className="section section-2-container fade-in-section"
            id="section-1"
          >
            <div className="overlay" />
            <img alt="" src={section2Bg} className="bg-img" />
            <img alt="" src={section2Img2} className="bg-img-2" />

            <div className="layer-1">
              <div className="text-container">
                <img alt="" src={promenadeImg} className="asset-1" />
                <div
                  className="scroll-container-synopsis"
                  ref={section2ScrollDiv}
                >
                  <p style={{ paddingRight: 30 }}>
                    <div
                      className="text-container-heading"
                      style={{ fontWeight: 600 }}
                    >
                      Modern, Iconic, Sustainable Commercial Marvel
                    </div>
                    <div>
                      Baashyaam’s Promenade in Guindy is an Iconic, Modern
                      Commercial Marvel, that looms spectacularly large right on
                      the spine of Anna Salai.
                    </div>
                    <div>
                      Refined aesthetic detailing is omnipresent throughout the
                      construction. This strategically located corner site is
                      flanked on either side by approach roads (in the South
                      East and South directions).
                    </div>
                    <div>
                      Contemporary and innovative, it effortlessly embraces
                      design thinking, raw materials and technology. The strong
                      emphasis is on unprecedented scale, grandeur and
                      sustainability.
                    </div>
                  </p>
                  <p>
                    <div
                      className="text-container-heading"
                      style={{ fontWeight: 600 }}
                    >
                      Impressive Specs
                    </div>
                    <div>Developed on a 5.6 acre land parcel</div>
                    <div>A standalone, 63.5m tall towering structure</div>
                    <div>1.81 Million Sq.ft. Built-up Area</div>
                    <div>
                      11 office floors, designed for premium Commercial/IT
                      offices
                    </div>
                    <div>
                      A large atrium space to ensure internal vertical
                      connectivity between office floors
                    </div>
                    <div>5 levels of parking</div>
                    <div>
                      Ground floor dedicated to retail, bespoke services and an
                      automobile showroom.
                    </div>
                  </p>

                  <p>
                    <div
                      className="text-container-heading"
                      style={{ fontWeight: 600 }}
                    >
                      U.S. Green Building Council and Green Business
                      Certification Inc. has conferred Baashyaam Promenade with
                      Platinum Pre-certification Rating under the LEED v4
                      Building Design and Construction (Core and Shell
                      Development Rating System)
                    </div>
                    <div>
                      This acknowledges the commitment, efforts and certifies
                      that all the necessary pre-requisite Leadership steps have
                      been taken towards constructing a Leed-Certified,
                      High-Performance, Green Building.
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section
            className="section section-3-container fade-in-section"
            id="section-2"
          >
            <img alt="" src={section3Bg} className="bg-img" />
            <div className="layer-1">
              <div
                className={`box left-box ${
                  section3CurrentIndex === 1 ? "current-box" : ""
                }`}
              >
                <div className="box-heading">
                  <img
                    src={icon1}
                    alt=""
                    className="mini-icon"
                    style={{ opacity: section3CurrentIndex === 1 ? 0 : 1 }}
                  />
                  <img
                    src={icon2}
                    alt=""
                    className="mini-icon"
                    style={{ opacity: section3CurrentIndex === 1 ? 1 : 2 }}
                  />
                  OUTDOOR AMENITIES
                </div>
                <div className="box-container">
                  <span>Arrival Plaza</span>
                  <span>Outdoor Gym</span>
                  <span>Outdoor Plug and play</span>
                  <span>Amphitheatre</span>
                  <span>Outdoor Seating</span>
                  <span>Outdoor Work Points - Huddle Spaces</span>
                  <span>Walking Track</span>
                  <span>Breakout Areas</span>
                  <span>Breakout Zones</span>
                  <span>Water bodies</span>
                </div>
              </div>
              <div
                className={`box mid-box ${
                  section3CurrentIndex === 0 ? "current-box" : ""
                }`}
              >
                <div
                  className="selection-box"
                  style={{
                    left:
                      section3CurrentIndex === 0
                        ? "0px"
                        : section3CurrentIndex === 1
                        ? "-100%"
                        : "100%",
                  }}
                ></div>
                <div className="box-heading">
                  <img
                    src={icon1}
                    alt=""
                    className="mini-icon"
                    style={{ opacity: section3CurrentIndex === 0 ? 0 : 1 }}
                  />
                  <img
                    src={icon2}
                    alt=""
                    className="mini-icon"
                    style={{ opacity: section3CurrentIndex === 0 ? 1 : 2 }}
                  />
                  PROJECT SPECIFICATIONS
                </div>
                <div className="box-container">
                  <span>Total Project Saleable Area 1.2 Million Sq.ft.</span>
                  <span>Adequate Parking</span>
                  <span>Platinum Rating LEED Certiﬁcation</span>
                  <span>100% DG Back up</span>
                  <span>EV Charging Provision</span>
                  <span>Integrated BMS System</span>
                  <span>
                    24 Hour Security with CCTV Surveillance, Turnstile & Access
                    Control
                  </span>
                  <span>Efficient Waste Management System</span>
                  <span>Driver's Waiting room & restrooms</span>
                  <span>Collaborative spaces</span>
                  <span>Multiple entry exit points</span>
                </div>
              </div>
              <div
                className={`box right-box ${
                  section3CurrentIndex === 2 ? "current-box" : ""
                }`}
              >
                <div className="box-heading">
                  <img
                    src={icon1}
                    alt=""
                    className="mini-icon"
                    style={{ opacity: section3CurrentIndex === 2 ? 0 : 1 }}
                  />
                  <img
                    src={icon2}
                    alt=""
                    className="mini-icon"
                    style={{ opacity: section3CurrentIndex === 2 ? 1 : 2 }}
                  />
                  INDOOR AMENITIES
                </div>
                <div className="box-container">
                  <span>Coffee Bar</span>
                  <span>Auditorium With Pre Function Area</span>
                  <span>Indoor Cafeteria</span>
                  <span>Creche With Outdoor Play Area</span>
                  <span>Fitness Centre</span>
                  <span>Business Lounge</span>
                  <span>Meeting Rooms</span>
                  <span>Huddle Spaces</span>
                  <span>Retail - Car Showroom</span>
                  <span>Collaborative/Huddle spaces</span>
                  <span>Mail/Parcel room</span>
                  <span>Medical room</span>
                </div>
              </div>
            </div>
          </section>

          <img
            alt=""
            src={section4Bg}
            ref={section4BgRef}
            className={`bg-img section-4-bg ${showSection4Bg ? "" : ""}`}
            style={{ opacity: showSection4Bg ? 1 : 0 }}
          />
          <section
            className="section section-4-container fade-in-section"
            id="section-3"
          >
            <div ref={section4Ref1} className={`layer-1`}>
              <div className="heading">
                <img src={icon1} alt="" className="mini-icon" />
                GALLERY
              </div>
              <div className="tab-menu">
                <span
                  className={`${
                    section4SelectedTabIndex === 0 ? "selected" : ""
                  }`}
                  onClick={() => setSection4SelectedTabIndex(0)}
                >
                  Exterior
                </span>
                <span
                  className={`${
                    section4SelectedTabIndex === 1 ? "selected" : ""
                  }`}
                  onClick={() => setSection4SelectedTabIndex(1)}
                >
                  Interior
                </span>
              </div>
              {section4SelectedTabIndex === 0 ? (
                <div className="main-content main-content-tab-1">
                  <div className={`image-row row-1`}>
                    <div
                      className={`image-container ${
                        selected4Image === 1 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected4Image(1);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={extImg1} />
                    </div>
                    <div
                      className={`image-container ${
                        selected4Image === 2 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected4Image(2);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={extImg2} />
                    </div>

                    <div
                      className={`image-container ${
                        selected4Image === 3 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected4Image(3);
                        setClickedImage(true);
                      }}
                    >
                      <img
                        alt=""
                        src={extImg3}
                        style={{
                          opacity: section4SelectedTabIndex === 0 ? 1 : 0,
                        }}
                      />
                    </div>
                    <div
                      className={`image-container ${
                        selected4Image === 4 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected4Image(4);
                        setClickedImage(true);
                      }}
                    >
                      <img
                        alt=""
                        src={extImg4}
                        style={{
                          opacity: section4SelectedTabIndex === 0 ? 1 : 0,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="main-content">
                  <div className={`image-row row-1`}>
                    <div
                      className={`image-container ${
                        selected4Image === 1 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected4Image(1);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={intImg1} />
                    </div>
                    <div
                      className={`image-container ${
                        selected4Image === 2 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected4Image(2);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={intImg2} />
                    </div>
                    <div
                      className={`image-container ${
                        selected4Image === 3 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected4Image(3);
                        setClickedImage(true);
                      }}
                    >
                      <img
                        alt=""
                        src={intImg3}
                        style={{
                          opacity: section4SelectedTabIndex === 0 ? 0 : 1,
                        }}
                      />
                    </div>
                    <div
                      className={`image-container ${
                        selected4Image === 4 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected4Image(4);
                        setClickedImage(true);
                      }}
                    >
                      <img
                        alt=""
                        src={intImg4}
                        style={{
                          opacity: section4SelectedTabIndex === 0 ? 0 : 1,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="image-row row-2"
                    style={{ zIndex: selected4Image > 4 ? 2 : 1 }}
                  >
                    <div
                      className={`image-container ${
                        selected4Image === 5 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected4Image(5);
                        setClickedImage(true);
                      }}
                    >
                      <img
                        alt=""
                        src={intImg5}
                        style={{
                          opacity: section4SelectedTabIndex === 0 ? 0 : 1,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>

          <section
            className="section section-5-container fade-in-section"
            id="section-4"
          >
            <div ref={section5Ref1} className={`layer-1`}>
              <div className="heading">
                <img src={icon1} alt="" className="mini-icon" />
                PROJECT STATUS
              </div>
              <div className="tab-menu">
                <span
                  className={`${
                    section5SelectedTabIndex === 5 ? "selected" : ""
                  }`}
                  onClick={() => setSection5SelectedTabIndex(5)}
                >
                  November 2024
                </span>
                <span
                  className={`${
                    section5SelectedTabIndex === 4 ? "selected" : ""
                  }`}
                  onClick={() => setSection5SelectedTabIndex(4)}
                >
                  October 2024
                </span>
                <span
                  className={`${
                    section5SelectedTabIndex === 3 ? "selected" : ""
                  }`}
                  onClick={() => setSection5SelectedTabIndex(3)}
                >
                  September 2024
                </span>
                <span
                  className={`${
                    section5SelectedTabIndex === 2 ? "selected" : ""
                  }`}
                  onClick={() => setSection5SelectedTabIndex(2)}
                >
                  August 2024
                </span>
                <span
                  className={`${
                    section5SelectedTabIndex === 1 ? "selected" : ""
                  }`}
                  onClick={() => setSection5SelectedTabIndex(1)}
                >
                  July 2024
                </span>
                <span
                  className={`${
                    section5SelectedTabIndex === 0 ? "selected" : ""
                  }`}
                  onClick={() => setSection5SelectedTabIndex(0)}
                >
                  June 2024
                </span>
              </div>
              {section5SelectedTabIndex === 5 ? (
                <div className="main-content">
                  <div className={`image-row row-1`}>
                    <div
                      className={`image-container ${
                        selected5Image === 1 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(1);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={november2024Img1} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 2 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(2);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={november2024Img2} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 3 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(3);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={november2024Img3} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 4 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(4);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={november2024Img4} />
                    </div>
                  </div>
                </div>
              ) : section5SelectedTabIndex === 4 ? (
                <div className="main-content">
                  <div className={`image-row row-1`}>
                    <div
                      className={`image-container ${
                        selected5Image === 1 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(1);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={october2024Img1} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 2 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(2);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={october2024Img2} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 3 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(3);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={october2024Img3} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 4 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(4);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={october2024Img4} />
                    </div>
                  </div>
                </div>
              ) : section5SelectedTabIndex === 3 ? (
                <div className="main-content">
                  <div className={`image-row row-1`}>
                    <div
                      className={`image-container ${
                        selected5Image === 1 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(1);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={AugustImg1} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 2 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(2);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={AugustImg2} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 3 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(3);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={AugustImg3} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 4 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(4);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={AugustImg4} />
                    </div>
                  </div>
                </div>
              ) : section5SelectedTabIndex === 2 ? (
                <div className="main-content">
                  <div className={`image-row row-1`}>
                    <div
                      className={`image-container ${
                        selected5Image === 1 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(1);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={AugustImg1} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 2 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(2);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={AugustImg2} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 3 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(3);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={AugustImg3} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 4 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(4);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={AugustImg4} />
                    </div>
                  </div>
                </div>
              ) : section5SelectedTabIndex === 1 ? (
                <div className="main-content">
                  <div className={`image-row row-1`}>
                    <div
                      className={`image-container ${
                        selected5Image === 1 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(1);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={julyImg1} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 2 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(2);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={julyImg2} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 3 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(3);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={julyImg3} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 4 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(4);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={julyImg4} />
                    </div>
                  </div>

                  <div className={`image-row row-2`}>
                    <div
                      className={`image-container ${
                        selected5Image === 5 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(5);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={julyImg5} />
                    </div>
                  </div>
                </div>
              ) : section5SelectedTabIndex === 0 ? (
                <div className="main-content">
                  <div className={`image-row row-1`}>
                    <div
                      className={`image-container ${
                        selected5Image === 1 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(1);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={juneImg1} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 2 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(2);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={juneImg2} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 3 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(3);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={juneImg3} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 4 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(4);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={juneImg4} />
                    </div>
                  </div>

                  <div className={`image-row row-2`}>
                    <div
                      className={`image-container ${
                        selected5Image === 5 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(5);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={juneImg5} />
                    </div>
                    <div
                      className={`image-container ${
                        selected5Image === 6 ? "zoom-img" : ""
                      }`}
                      onClick={() => {
                        setSelected5Image(6);
                        setClickedImage(true);
                      }}
                    >
                      <img alt="" src={juneImg6} />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </section>

          <section
            className="section section-6-container fade-in-section"
            id="section-5"
          >
            {/* <div className="overlay" /> */}
            <div className="layer-1" ref={section6Ref1}>
              <div className="image-container">
                <div className="text-container">
                  <div className="heading">
                    <img src={icon1} alt="" className="mini-icon" />
                    LOCATION
                  </div>
                  {/* <img alt="" src={section6Bg} className="bg-img" /> */}
                  <div className="google-maps-iframe-container">
                    <iframe
                      title="google map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.357636798076!2d80.2171793756897!3d13.01288258730636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52670042213b65%3A0x242048801d3ad3d1!2sBaashyaam%20Promenade!5e0!3m2!1sen!2sin!4v1717319506721!5m2!1sen!2sin"
                      style={{ border: 0, width: "60vw", height: 255 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <div className="logo-container">
                      <img alt="" src={promenadeImg} className="asset-1" />
                      <span className="address-text">
                        Anna Salai, Little Mount, Guindy, Chennai, Tamil Nadu –
                        600032.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="section section-7-container fade-in-section"
            id="section-6"
          >
            {/* <div className="overlay" /> */}
            <div className="layer-1" ref={section7Ref1}>
              <div className="image-container">
                <img alt="" src={section7Bg} className="bg-img" />
                <div className="btn-container">
                  <span className={`arrow-container`} {...handlers}></span>
                  <span className="text"></span>
                </div>
              </div>
            </div>

            {/* <footer className="footer bg-light">
      <div className="footer-pad border-bottom">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4 d-flex justify-content-center justify-content-lg-start mb-md-0 mb-2 flex-wrap">
              <a href="tel:+919840477777">+91 9840477777</a>
              <span className="ml-2 mr-2"> | </span>
              <a href="mailto:enquiry@bashyamgroup.com">enquiry@bashyamgroup.com</a>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-md-center mb-md-0 mb-2">
              <ul className="list-unstyled d-flex m-0 p-0">
                <li>
                  <a className="mr-2 ml-2" href="https://www.facebook.com/BaashyaamConstructions">
                  </a>
                </li>
                <li>
                  <a className="mr-2 ml-2" href="https://twitter.com/BaashyaamGroup">
                  </a>
                </li>
                <li>
                  <a className="mr-2 ml-2" href="https://www.instagram.com/baashyaam_constructions/">
                  </a>
                </li>
                <li>
                  <a className="mr-2 ml-2" href="https://www.linkedin.com/company/baashyaam-constructions">
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 d-flex justify-content-center justify-content-lg-end mb-md-0 mb-2 flex-wrap">
              <a href="/privacy-policy/">Privacy policy</a>
              <span className="ml-2 mr-2"> | </span>
              <a href="/terms/">Terms and Conditions</a>
              <span className="ml-2 mr-2"> | </span>
              <a href="/joint-venture/">Joint venture</a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-pad">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-6 d-flex justify-content-center justify-content-md-start">
            </div>
            <div className="col-sm-6 d-flex justify-content-center justify-content-md-end">
            </div>
          </div>
        </div>
      </div>
            </footer> */}

            <Footer />
          </section>
        </div>
      </div>

      {showImageModal ? (
        <Modal
          closable
          styles={{ mask: { background: "rgba(0, 0, 0, 0.65)" } }}
          className="image-modal-web"
          open={true}
          onCancel={() => {
            setSelected4Image(false);
            setClickedImage(false);
            setShowImageModal(false);
            setSelected5Image(false);
            sliderModalRef.current = 0;
          }}
          footer={false}
        >
          {selected4Image ? (
            <div
              style={{ opacity: isLoading ? 0 : 1 }}
              className={`carousel-container ${
                clickedImage ? "" : "set-animate"
              }`}
              id="carousel-container-4"
            >
              {section4SelectedTabIndex === 0 ? (
                <>
                  <div className="carousel_item">
                    <img alt="" src={extImg1} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={extImg2} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={extImg3} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={extImg4} />
                  </div>
                </>
              ) : (
                <>
                  <div className="carousel_item">
                    <img alt="" src={intImg1} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={intImg2} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={intImg3} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={intImg4} />
                  </div>
                  <div className="carousel_item">
                    <img alt="" src={intImg5} />
                  </div>
                </>
              )}
              <div className="pagination">
                <span>{selected4Image}</span>/<span>{total4Image}</span>
              </div>
              {showLeft4Arrow ? (
                <LeftOutlined onClick={() => slideLeft(4)} />
              ) : (
                <></>
              )}
              {showRight4Arrow ? (
                <RightOutlined onClick={() => slideRight(4)} />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          {selected5Image ? (
            section5SelectedTabIndex === 3 ? (
              <div
                style={{ opacity: isLoading ? 0 : 1 }}
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
              >
                <div className="carousel_item">
                  <img alt="" src={septImg1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={septImg2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={septImg3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={septImg4} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {showLeft5Arrow ? (
                  <LeftOutlined onClick={() => slideLeft(5)} />
                ) : (
                  <></>
                )}
                {showRight5Arrow ? (
                  <RightOutlined onClick={() => slideRight(5)} />
                ) : (
                  <></>
                )}
              </div>
            ) : section5SelectedTabIndex === 2 ? (
              <div
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
              >
                <div className="carousel_item">
                  <img alt="" src={AugustImg1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={AugustImg2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={AugustImg3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={AugustImg4} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {showLeft5Arrow ? (
                  <LeftOutlined onClick={() => slideLeft(5)} />
                ) : (
                  <></>
                )}
                {showRight5Arrow ? (
                  <RightOutlined onClick={() => slideRight(5)} />
                ) : (
                  <></>
                )}
              </div>
            ) : section5SelectedTabIndex === 1 ? (
              <div
                style={{ opacity: isLoading ? 0 : 1 }}
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
              >
                <div className="carousel_item">
                  <img alt="" src={julyImg1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={julyImg2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={julyImg3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={julyImg4} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={julyImg5} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {showLeft5Arrow ? (
                  <LeftOutlined onClick={() => slideLeft(5)} />
                ) : (
                  <></>
                )}
                {showRight5Arrow ? (
                  <RightOutlined onClick={() => slideRight(5)} />
                ) : (
                  <></>
                )}
              </div>
            ) : section5SelectedTabIndex === 0 ? (
              <div
                style={{ opacity: isLoading ? 0 : 1 }}
                className={`carousel-container ${
                  clickedImage ? "" : "set-animate"
                }`}
                id="carousel-container-5"
              >
                <div className="carousel_item">
                  <img alt="" src={juneImg1} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg2} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg3} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg4} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg5} />
                </div>
                <div className="carousel_item">
                  <img alt="" src={juneImg6} />
                </div>
                <div className="pagination">
                  <span>{selected5Image}</span>/<span>{total5Image}</span>
                </div>
                {showLeft5Arrow ? (
                  <LeftOutlined onClick={() => slideLeft(5)} />
                ) : (
                  <></>
                )}
                {showRight5Arrow ? (
                  <RightOutlined onClick={() => slideRight(5)} />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Modal>
      ) : (
        ""
      )}

      <Modal
        centered
        className="user-form-modal-web"
        open={showModal}
        onCancel={() => {
          setShowModal(false);
          clearFormData();
        }}
        footer={false}
      >
        <div className="heading">Please fill the form</div>
        <Form layout={formLayout} form={form}>
          <Form.Item label="Name">
            <span
              className="error-text-form"
              style={{
                left: "4.5vw",
                display: showRequiredError[0] ? "block" : "none",
              }}
            >
              Required
            </span>
            <Input
              placeholder="Enter Name"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              className={showRequiredError[0] ? "has-error" : ""}
            />
          </Form.Item>
          <Form.Item label="Contact Number">
            <span
              className="error-text-form"
              style={{
                left: "9.2vw",
                display: showRequiredError[1] ? "block" : "none",
              }}
            >
              Required
            </span>
            <Input
              placeholder="Enter Contact Number"
              value={formContact}
              onChange={(e) => setFormContact(e.target.value)}
              className={showRequiredError[1] ? "has-error" : ""}
            />
          </Form.Item>
          <Form.Item label="Email">
            <span
              className="error-text-form"
              style={{
                left: "4.5vw",
                display: showRequiredError[2] ? "block" : "none",
              }}
            >
              Required
            </span>
            <Input
              placeholder="Enter Email"
              value={formEmail}
              onChange={(e) => setFormEmail(e.target.value)}
              className={showRequiredError[2] ? "has-error" : ""}
            />
          </Form.Item>
          <Form.Item className="text-area" label="Message">
            <span
              className="error-text-form"
              style={{
                left: "6vw",
                display: showRequiredError[3] ? "block" : "none",
              }}
            >
              Required
            </span>
            <TextArea
              rows={4}
              placeholder="Message......."
              value={formMessage}
              onChange={(e) => setFormMessage(e.target.value)}
              className={showRequiredError[3] ? "has-error" : ""}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                submitFormData();
              }}
            >
              Submit
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShowModal(false);
                clearFormData();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        className="user-form-success-modal-web"
        open={showSuccessModal}
        closable={false}
        footer={false}
      >
        <div>Thank you for enquiring about our prestigious project. </div>
        <div>Our Product Specialist will contact you shortly.</div>
      </Modal>
    </>
  );
}

export default PromenadeWeb;
